.typewriter {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing 3s steps(20, end) forwards;
}
.typewriter.no-animation {
  animation: none !important;
  width: 100%;
}

@keyframes typing {
  0% {
    width: 0;
  }
  20% {
    width: 8ch;
  }
  70% {
    width: 8ch;
  }
  100% {
    width: 100%;
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.fade-in-section.no-animation {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: none;
}
